.interactive:hover {
  cursor: pointer; }

.interactive.inactive {
  color: lightslategrey; }

button.btn {
  margin-bottom: 0.5em; }

div.control {
  text-align: center; }
  div.control svg {
    margin-left: 0.5em;
    margin-right: 0.5em; }

.currentLine {
  background: rgba(90, 205, 90, 0.305);
  position: absolute;
  width: 100% !important;
  left: 0 !important; }
